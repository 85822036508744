/*
 * @Descripttion: 维修接口
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-12-09 23:41:08
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2020-12-14 00:08:14
 */
import request from "../request"

// 工种
export const workType = function () {
    return request.get('/other/typeWork')
}

// 接单
export const order = function (data) {
    return request.post('/workStaff/confirmMasterUser', data)
}

// 填写时间
export const fillTime = function (data) {
    return request.post('/workStaff/fillTime', data)
}

// 师傅工单详情
export const orderDetail = function (data) {
    return request.post('/workStaff/getWorkDetail', data)
}

// 提交报价单
export const submitQuotation = function (data) {
    return request.post('/workStaff/submitQuotation', data)
}

// 提交报告
export const submitReport = function (data) {
    return request.post('/workStaff/submitReport', data)
}

// 获取待结算金额
export const settlementAmount = function () {
    return request.get('/workStaff/getSettlementAmount')
}