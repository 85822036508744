<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-14 16:29:32
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2020-12-14 01:36:23
-->
<template>
  <div class="page-box">
    <list @load="getList">
      <div class="header flex-x center">
        <span class="header__title">本月待结算金额</span>
        <span class="header__price grow">{{ amount }}</span>
        <div class="header__picker flex-x center" @click="showMonth = true">
          <span class="grow">{{ month }}月</span>
          <div class="header__arrow"></div>
        </div>
      </div>
      <div class="order" v-for="(item, index) in list" :key="index">
        <order-card
          :info="item"
          :btns="checkBtn(item, btns)"
          showSeal
          :sealIcon="item.label.status === 0 ? waitSettleIcon : settledIcon"
          @onclickbtn="clickBtn"
          @ondetail="detail"
        />
      </div>
    </list>

    <popup v-model="showMonth" position="bottom">
      <datetime-picker
        type="year-month"
        @cancel="showMonth = false"
        @confirm="selectMonth"
      />
    </popup>

    <msg-box v-model="showNone" @onclick="$router.back()" />
  </div>
</template>

<script>
import orderCard from "@/components/OrderCard/OrderCard";
import { orderList } from "../../../utils/api/otherWorkOrder";
import { order, fillTime, settlementAmount } from "../../../utils/api/service";
import pageLoader from "../../../utils/load_per_page";
import { List, Toast, DatetimePicker, Popup } from "vant";
import msgBox from "../../../components/msgBox/msgBox";
export default {
  components: {
    "order-card": orderCard,
    list: List,
    "msg-box": msgBox,
    popup: Popup,
    "datetime-picker": DatetimePicker,
  },
  data() {
    return {
      waitSettleIcon: require("../../../assets/images/user/review_waitsettle.png"), // 待结算图片
      settledIcon: require("../../../assets/images/user/review_settled.png"), // 已结算图标

      listLoader: null,

      list: [],

      showNone: false, // 显示没有列表

      showTime: false, // 显示勘察时间
      prepareSurveyId: 0, // 准备勘察的id

      btns: [],

      year: new Date().getFullYear(), // 当前选中年
      month: new Date().getMonth() + 1, // 当前选中月

      showMonth: false,

      amount: 0, // 结算金额
    };
  },

  created() {
    this.getAmount();
  },

  methods: {
    // 获取当月结算金额
    getAmount() {
      settlementAmount({
        month: `${this.year}-${this.month}`,
      }).then((res) => {
        this.amount = res.data.data.amount;
      });
    },

    // 获取列表
    getList() {
      if (!this.listLoader)
        this.listLoader = new pageLoader(
          orderList,
          "data.data.list.data",
          true,
          "data.data.list.last_page"
        );
      this.listLoader
        .load({
          status: 4,
          month: `${this.year}-${this.month}`,
        })
        .then((res) => {
          if (res && res.length === 0) this.showNone = true;
          if (res && res.length) {
            this.showNone = false;
            this.list = res;
          }
        })
        .catch((res) => {});
    },

    // 重新获取列表
    reGetList() {
      this.listLoader.reLoad();
      this.getList();
    },

    // 检查按钮并返回应该显示
    checkBtn(info, btns) {
      let result = [];

      for (let i = 0; i < btns.length; i++) {
        // 如果按钮没有条件
        if (!btns[i].condition) {
          result.push(btns[i]);
          continue;
        }

        let condition = btns[i].condition;
        let checkPassed = true; // 检查通过

        for (let j in condition) {
          let position = j.split(".");
          let value = info;
          while (position.length > 0) {
            value = value[position[0]];
            position.splice(0, 1);
          }
          if (condition[j] !== value) {
            checkPassed = false;
            break;
          }
        }

        if (checkPassed) result.push(btns[i]);
      }

      return result;
    },

    // 点击按钮
    clickBtn(e) {
      if (!e.detail.method) return false;
      this[e.detail.method](e.info.id, e.info);
    },

    // 上门时间
    survey(id, info) {
      this.showTime = true;
      this.prepareSurveyId = id;
    },

    // 上传上门时间
    submitSurvey(e) {
      let info = e.detail;
      info.hour = JSON.stringify(info.hour);
      fillTime({
        id: this.prepareSurveyId,
        type: 1,
        ...info,
      })
        .then((res) => {
          Toast.success("填写成功");
          this.showTime = false;
          this.reGetList();
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },

    // 提交报告
    submitReport(id) {
      this.$router.push({
        name: "ServiceReport",
        query: {
          id,
        },
      });
    },

    // 选择年月
    selectMonth(e) {
      this.year = e.getFullYear();
      this.month = e.getMonth() + 1;
      this.showMonth = false;
      this.getAmount();
      this.reGetList();
    },

    // 详情
    detail(e) {
      this.$router.push({
        name: "ReviewOrderDetail",
        query: {
          id: e.info.id,
        },
      });
    },
  },
};
</script> 

<style src="@/assets/css/review/newTicket.css" scoped>
</style>

<style scoped>
.header {
  width: 100%;
  height: 98px;
  position: sticky;
  z-index: 99999;
  top: 0;
  background-color: white;
  box-sizing: border-box;
  padding: 0 33px;
}

.header__title {
  font-size: 28px;
  color: #222;
}

.header__price {
  font-size: 46px;
  color: var(--main-color);
  text-decoration: underline;
  margin-left: 18px;
}

.header__picker {
  width: 204px;
  height: 58px;
  border: 1px solid #999;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 0 12px 0 21px;
  font-size: 28px;
}

.header__arrow {
  width: 42px;
  height: 42px;
  background-image: url("../../../assets/images/review/form_arrow.png");
  background-size: contain;
  background-repeat: no-repeat;
}
</style>